


























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DeviceTile from './DeviceTile.vue';
import ParticipantTile from './ParticipantTile.vue';
import {
  DashboardConfig,
  DeviceSettingKindPermissions,
  MonitoringDashboardMode,
} from '@/apps/monitoring/interfaces';
import { DeviceRelation } from '@/models/data/models';
import { Device, DeviceSettingKind } from '@/models/device/models';
import { globalStore } from '@/store/modules/global';
import { Participant } from '@/models/study/models';
import { Context } from '@/api/ApiClientV2';
import {
  getFromRouteOrStorage,
  initializeStandaloneMonitoringDashboard,
} from '../standalone';

@Component({
  components: {
    DeviceTile,
    ParticipantTile,
  },
})
export default class MonitoringDashboard extends Vue {
  @Prop({ default: false }) standalone!: boolean;

  refreshId = 0;
  pollInterval = 15;
  active = true;
  activeTimerId = 0;
  mode: MonitoringDashboardMode = 'device';
  deviceRelations: string[] = [];
  loaded = false;
  permissions: DeviceSettingKindPermissions = {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    owner: false,
    view_setting: false,
    edit_setting: false,
    access_setting: false,
  };
  participants: Participant[] = [];

  @Watch('$route.query')
  async onRouteChanged(): Promise<void> {
    if (this.loaded) {
      if (this.standalone) {
        await initializeStandaloneMonitoringDashboard(this.$routerHandler);
      }
      this.init();
    }
  }

  get hasDetailView(): boolean {
    return !this.standalone;
  }

  get canEditDeviceSettings(): boolean {
    return this.permissions?.edit_setting && !this.standalone;
  }

  async mounted(): Promise<void> {
    document.addEventListener('visibilitychange', this.handleHidden);

    try {
      if (this.standalone) {
        await initializeStandaloneMonitoringDashboard(this.$routerHandler);
      }

      await this.init();
      this.refreshId = setInterval(() => {
        this.init();
      }, 30 * 1000);
    } catch (error) {
      this.loaded = true;
      this.$errorHandler.handleError(error);
    }
  }

  destroyed(): void {
    document.removeEventListener('visibilitychange', this.handleHidden);
    clearInterval(this.refreshId);
  }

  async init(): Promise<void> {
    try {
      // fetch client app settings and keep in store
      await globalStore.fetchClientAppSettings();

      const configSettings = globalStore.clientAppSetting('config');
      if (!configSettings) {
        throw new Error('Client app setting "config" missing.');
      }
      const config: DashboardConfig = configSettings.value;
      this.pollInterval = config.pollInterval || this.pollInterval;
      this.mode = config.mode || 'device';

      if (this.mode === 'device' || this.mode === 'gateway') {
        await this.getDeviceRelations();
      } else if (this.mode === 'participant') {
        await this.getParticipants();
      } else {
        throw new Error(`Invalid mode ${this.mode}.`);
      }

      await this.getPermissions();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    this.loaded = true;
  }

  async getDeviceRelations(): Promise<void> {
    if (this.standalone) {
      this.deviceRelations = await this.getDeviceRelationsFromRouteOrStorage();
    } else {
      this.deviceRelations = await this.getDeviceRelationsFromSetting();
    }
  }

  async getDeviceRelationsFromRouteOrStorage(): Promise<string[]> {
    const deviceRelationList = await getFromRouteOrStorage(
      this.$routerHandler,
      'device_relations',
    );
    return deviceRelationList?.split(',').map(d => d.trim()) ?? [];
  }

  async getDeviceRelationsFromSetting(): Promise<string[]> {
    const deviceRelationSettings = globalStore.clientAppSetting(
      'device_relations',
    );
    if (!deviceRelationSettings) {
      throw new Error('Client app setting "device_relations" missing.');
    }
    return deviceRelationSettings.value.device_relations;
  }

  async getParticipants(): Promise<void> {
    if (this.standalone) {
      this.participants = await this.getParticipantsFromRouteOrStorage();
    } else {
      this.participants = await this.getParticipantsFromSetting();
    }
  }

  async getParticipantsFromRouteOrStorage(): Promise<Participant[]> {
    const participantId = await getFromRouteOrStorage(
      this.$routerHandler,
      'participant',
    );
    const participant = await this.$apiv2.get<Participant>(
      Participant,
      participantId,
    );
    return [participant];
  }

  async getParticipantsFromSetting(): Promise<Participant[]> {
    const configSettings = globalStore.clientAppSetting('config');
    if (!configSettings) {
      throw new Error('Client app setting "config" missing.');
    }
    const config: DashboardConfig = configSettings.value;
    const context: Context = {
      filter: {
        state: config.participantState,
      },
      pagination: {
        page: 1,
        pageSize: 100,
      },
    };
    return this.$apiv2.getListItems<Participant>(Participant, context);
  }

  /**
   * @vuese
   * To reduce the server load, the monitoring dashboard is deactivated when the document is hidden
   * (see [here](https://developer.mozilla.org/en-US/docs/Web/API/Document/hidden)).
   * This means when the user switches to another browser tab or minimizes the browser window,
   * the dashboard stops refreshing data and making API calls (after a timeout of 1 min).
   * As soon as the dashboard becomes active again,
   * the dashboard is refreshed and activated again. To avoid a possible error state where the
   * dashboard is still visible but does not refresh data, the `v-if` directive is placed on a surrounding `div` tag,
   * meaning that the dashboard is not displayed anymore at all as soon as it's deactivated.
   * See also `visibilitychange` event listeners in the mounted and destroyed lifecycle hooks.
   */
  handleHidden(): void {
    if (this.activeTimerId !== undefined) {
      clearTimeout(this.activeTimerId);
    }
    if (document && document.hidden === true) {
      // deactivate after 1 min
      this.activeTimerId = setTimeout(() => {
        this.active = false;
      }, 60000);
    } else {
      this.active = true;
    }
  }

  /**
   * @vuese
   * Get device.devicesettingkind permissions of currently logged in user
   */
  async getPermissions(): Promise<void> {
    if (this.deviceRelations.length > 0) {
      try {
        const deviceRelation = await this.$apiv2.get<DeviceRelation>(
          DeviceRelation,
          this.deviceRelations[0],
        );
        const device = await this.$apiv2.get<Device>(
          Device,
          deviceRelation.device,
        );
        const deviceSettingKind = await this.$apiv2.find<DeviceSettingKind>(
          DeviceSettingKind,
          {
            model: device.model,
            handle: 'default',
          },
        );
        this.permissions = {
          ...this.permissions,
          ...((deviceSettingKind._permissions as unknown) as DeviceSettingKindPermissions),
        };
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.permissions = {
            ...this.permissions,
            view: false,
            edit: false,
            delete: false,
            authorize: false,
            owner: false,
            view_setting: false,
            edit_setting: false,
            access_setting: false,
          };
        } else {
          this.$errorHandler.handleError(error);
        }
      }
    }
  }
}
