import { apiClientV2 } from '@/api/ApiClientV2';
import { ClientApp } from '@/models/client/models';
import { RouterHandler } from '@/router/routerHandler';
import { authStore } from '@/store/modules/auth/auth';
import { globalStore } from '@/store/modules/global';
import { getItem, setItem } from '@/util/storage';
import { AxiosRequestConfig } from 'axios';
import { beforeEnterMonitoringApp } from './router';

export async function initializeStandaloneMonitoringDashboard(
  routerHandler: RouterHandler,
): Promise<void> {
  const token = await getFromRouteOrStorage(routerHandler, 'token');
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  authStore.setAxiosConfig(axiosConfig);
  apiClientV2.setAxiosConfig(axiosConfig);

  const clientAppId = await getFromRouteOrStorage(routerHandler, 'client_app');
  const clientApp = await apiClientV2.get<ClientApp>(ClientApp, clientAppId);

  globalStore.setSelectedObject({
    objectType: 'clientApp',
    object: clientApp,
  });
  await beforeEnterMonitoringApp();
}

export async function getFromRouteOrStorage(
  routerHandler: RouterHandler,
  key: string,
): Promise<string> {
  const value = routerHandler.query('')[key];
  if (value) {
    await setItem(`monitoring_${key}`, value);
    routerHandler.removeFromQuery('', key);
    return value;
  } else {
    const value = await getItem<string>(`monitoring_${key}`);
    if (value) {
      return value;
    }
  }
  throw new Error(`${key} not provided`);
}
