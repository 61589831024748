import { DeviceModel } from '@/models/data/models';

export interface EventData {
  columns: (number | string)[];
  index: (number | string)[];
  data: (number | string)[][];
}

export interface TileConfig {
  pid: string;
  enabled: boolean;
  lastDisableEvent: string;
}

export interface DeviceBoxConfig {
  enabled: boolean;
  lastDisableEvent: string;
}

export type MonitoringDashboardMode = 'device' | 'gateway' | 'participant';

export interface DashboardConfig {
  /**
   * Mode of the monitoring dashboard:
   * - 'device': one tile per device (default)
   * - 'gateway': one tile per gateway and devices assigned to gateway are shown in that tile
   * - 'participant': one tile per participant
   */
  mode?: MonitoringDashboardMode;
  /**
   * Interval with which to poll for new data in seconds
   */
  pollInterval?: number;
  /**
   * Device plugins to use in this monitoring dashboard (only applies to mode 'gateway').
   * The order of the list here is the same order with which devices will be shown in the gateway tile.
   */
  devicePlugins?: string[];
  /**
   * Roles of devices to ignore for this dashboard.
   * Only applies to mode 'gateway'.
   */
  ignoreRoles?: string[];
  /**
   * Order with which devices will be shown in tile specified by array of roles, e.g. ["mmt", "core"]
   * (only applies to mode 'gateway' or 'participant')
   */
  roleOrder?: string[];
  /**
   * Participant state to use for monitoring dashboard, leave empty for all participants
   */
  participantState?: string;
  /**
   * Study client app handle.
   * (only applies to mode 'participant')
   */
  studyClientAppHandle?: string;
}

export interface BatteryStatus {
  capacity?: number;
  charging_rate?: number;
  is_charging?: boolean;
  is_on_charger?: boolean;
  voltage?: number;
  time: string;
}

export interface Thresholds {
  lower: number;
  upper: number;
}

/**
 * There are three levels of thresholds:
 * - warning -> when the tile should turn yellow, typically used for time
 * - danger -> when the tile should turn red, typically used for value
 * - hide -> when the value should be hidden, typically used for quality
 */
export interface ThresholdsConfig {
  warning?: Thresholds;
  danger?: Thresholds;
  hide?: Thresholds;
}

/**
 * Thresholds can be set for the value, the quality and the time of each parameter individually.
 * There are three levels of thresholds:
 * - warning -> when the tile should turn yellow, typically used for time
 * - danger -> when the tile should turn red, typically used for value
 * - hide -> when the value should be hidden, typically used for quality
 */
export interface ParameterThresholds {
  /**
   * Thresholds for the value
   */
  value?: ThresholdsConfig;
  /**
   * Threshold for the quality
   */
  quality?: ThresholdsConfig;
  /**
   * Threshold for wearing
   */
  wearing?: ThresholdsConfig;
  /**
   * Threshold for the time, in seconds relative to current time
   */
  time?: ThresholdsConfig;
}

/**
 * Transformations to apply before displaying the parameter in the tile
 */
export interface DisplayTransformation {
  /**
   * Unit to show for the parameter after applying the transformation
   */
  unit?: string;
  /**
   * Multiply the value of the parameter by a certain value, e.g. 0.001 to tranfrom from m°C to °C
   */
  scale?: number;
  /**
   * Offset to apply to parameter
   */
  offset?: number;
  /**
   * How many fraction digits to show
   */
  fractionDigits?: number;
}

export interface ParameterData {
  id: string;
  label: string;
  unit?: string;
  value?: number | string;
  quality?: number | string;
  qualityUnit?: string;
  qualityMap?: { [key: string]: string };
  wearing?: number | string;
  wearingUnit?: string;
  wearingMap?: { [key: string]: string };
  time?: string;
  thresholds?: ParameterThresholds;
  displayTransformation?: DisplayTransformation;
  hiddenValue?: string;
}

/**
 * Additional static text fields to show in tile
 */
export interface AdditionalField {
  /**
   * ID of this field, has to be unique per stream
   */
  id: string;
  /**
   * Label to show in the tile for this field
   */
  label: string;
  /**
   * Value to show for this field
   */
  value: string;
  /**
   * Optional unit to show for this field
   */
  unit?: string;
  /**
   * css class to apply to this field, options are:
   * - 'text-value' (default)
   * - 'value' (to use same font size as for number values)
   */
  class?: string;
}

export interface DeviceBoxData {
  parameters: ParameterData[];
  additionalFields?: AdditionalField[];
  time?: string;
}

/**
 * Configuration of the parameters to show in tile
 */
export interface ParameterConfig {
  /**
   * ID of this parameter, has to be unique per stream, e.g. 'spo2'
   */
  id: string;
  /**
   * Label to show in tile left of parameter, e.g. 'SpO2'
   */
  label: string;
  /**
   * Unit of parameter, e.g. '%'
   */
  unit: string;
  /**
   * Which column of the event contains the value of this parameter (check the data template)
   */
  valueColumn: number | string;
  /**
   * Which column of the event contains the quality value of this parameter
   */
  qualityColumn?: number | string;
  /**
   * Unit of the quality, e.g. '%'
   */
  qualityUnit?: string;
  /**
   * Mapping of quality values, e.g.
   * {
   *   "0": "bad",
   *   "1": "good"
   * }
   */
  qualityMap?: { [key: string]: string };
  /**
   * Which column of the event contains the wearing value of this parameter
   */
  wearingColumn?: number | string;
  /**
   * Unit of wearing, e.g. '%'
   */
  wearingUnit?: string;
  /**
   * Mapping of wearing values, e.g.
   * {
   *   "0": "not worn",
   *   "1": "worn"
   * }
   */
  wearingMap?: { [key: string]: string };
  /**
   * Which column of the event contains the timestamp of this parameter, set to 'useIndex' if timestamp is in index instead of columns
   */
  timeColumn: number | string | 'useIndex';
  /**
   * Thresholds for this parameter
   */
  thresholds: ParameterThresholds;
  /**
   * Transformations to apply before displaying the parameter in the tile
   */
  displayTransformation?: DisplayTransformation;
  /**
   * Value to show when parameter is hidden, default is 'N/A'
   */
  hiddenValue?: string;
}

/**
 * Configuration of
 * - which stream from the event database to use for this model in the monitoring dashboard
 * - which parameters of that stream to show
 */
export interface StreamConfig {
  /**
   * Which stream to use from the event database for this model, e.g. 'storage-sig'
   */
  stream: string;
  /**
   * Whether data is in `data_json` as string or in `data` as object
   */
  usesDataJsonString: boolean;
  /**
   * Whether data points in events are ascending or descending in time
   */
  timeAscending: boolean;
  /**
   * How many seconds to maximally go back to find a value within quality and wearing threshold (only the latest event from the event log is checked).
   * If not set or 0, only the most recent data point will be used.
   */
  checkLastSeconds?: number;
  /**
   * Whether to show gateway name
   */
  gatewayName?: {
    /**
     * Enable/disable showing of gateway name
     */
    show: boolean;
    /**
     * Label for gateway name, e.g. 'Gateway' or 'Location'
     */
    label: string;
  };
  /**
   * Configuration of the parameters to show in tile (see `ParameterConfig` for details)
   */
  parametersConfig: ParameterConfig[];
  /**
   * Additional static text fields to show in tile
   */
  additionalFields?: AdditionalField[];
}

/**
 * The configuration of a model to be used in the monitoring app
 */
export type ModelConfig = DeviceModel & {
  monitoringConfig: {
    streams: StreamConfig[];
    /**
     * Label to show for the device name (default: 'Device')
     */
    deviceNameLabel?: string;
    /**
     * Whether there is battery information in the device state for this model
     * (default: true for devices, false for gateway)
     */
    hasBatteryInfo?: boolean;
    /**
     * Option to hide the value when the device is charging or on the charger (default: false)
     */
    hideValueWhenChargingOrOnCharger?: boolean;
  };
};

export interface DeviceModelsClientAppSetting {
  device_models: ModelConfig[];
}

export enum ParameterState {
  OK = 10,
  WARNING = 20,
  DANGER = 30,
  HIDDEN = 40,
}

export interface ParameterCompositeState {
  id: string;
  state: {
    value: ParameterState;
    quality: ParameterState;
    wearing: ParameterState;
    time: ParameterState;
    all?: ParameterState;
  };
}

export type TileColor = 'is-white' | 'is-danger' | 'is-warning' | 'is-success';

export interface TileState {
  color: TileColor;
  isCurrentPid: boolean;
  enabled: boolean;
}
export interface DeviceBoxState {
  color: TileColor;
  hasNewData: boolean;
  parameters: ParameterCompositeState[];
  enabled: boolean;
}

export type TileParameter = ParameterData &
  Partial<ParameterCompositeState> & {
    display: { value: string | number; unit?: string };
  };

export interface DeviceSettingKindPermissions {
  view: boolean;
  edit: boolean;
  delete: boolean;
  authorize: boolean;
  owner: boolean;
  view_setting: boolean;
  edit_setting: boolean;
  access_setting: boolean;
}

export interface TileLinkMetaData {
  tokenId: string;
  name: string;
  created: string;
}
